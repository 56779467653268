

































































































































































































































import cloneDeep from 'lodash/cloneDeep'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength, sameAs } from 'vuelidate/lib/validators'
import { getFieldError } from '@/core/tools/forms/get_field_error'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'

@Component({
  mixins: [validationMixin],
  computed: {
    ...mapState(STORE_KEY, [
      'loaded', 'loading', 'fatalError', 'serverErrors',
      'form', 'formDirty', 'categories', 'suppliers', 'brands',
    ]),
  },
  methods: {
    getFieldError,
  },
  validations() {
    const rules: any = {
      name: { required },
      packing: { required },
      category: { required },
      patterns: { required },
      description: { required },
      product_image: { required },
      supplier: { required },
      brand: { required },
    }

    return {
      form: rules,
    }
  },
  components: {
    TitleBar,
  },
})
export default class CreateContractWithCompany extends Vue {

  public FILES_HOST = process.env.VUE_APP_UBIC_SERVER_ADDRESS

  public selectedOptions: any = []
  public selectedOptionsCat: any = []
  public selectedCategory: any = []
  public nextName: any = ''
  public nextPatterns: any = ''
  public prod: any = []
  public productItem: any = []
  public productIndex: any = null
  public nextId: any = 0
  public show: any = false
  public showItem: any = false
  public dropFile: any = null
  public subCattegories: any = []

  public suppliers_types: any = {
    producer: { name: 'производитель' },
    merchant: { name: 'магазин' },
    producer_merchant: { name: 'производитель-магазин' },
  }

  get suppliersTypesList() {
    return Object.keys(this.suppliers_types).map((k) => {
      const t: any = this.suppliers_types[k]
      return {
        name: t.name,
        value: k,
      }
    })
  }

  get isNew() {
    return !Number.isInteger(Number(this.$route.params.id))
  }

  get titleStack() {
    return [
      'Товарная номенклатура', (this.isNew ? 'Новая товарная номенклатура' : 'Редактирование товарной номенклатуры'),
    ]
  }

  public changeValue(field: string) {

    // @ts-ignore
    this.$v.form[field].$touch()
  }

  public changeValueCategory(value: any) {
    this.$store.dispatch(`${STORE_KEY}/changeFormValue`, {
      field: 'sub_category',
      newValue: value,
    })
    // @ts-ignore
    // this.$v.form["sub_category"].$touch();
  }

  public uploadFile(files: any) {
    const formData = new FormData()

    formData.append('file', files)

    // @ts-ignore
    this.$http
      .post(`/admins/rest/v1/uploads`, formData, {
        // emulateJSON: true,
        // headers: {
        //   'Content-Disposition': `form-data; name="photo"; filename="${file.name}"`
        // }
      })
      .then((response: any) => {
        this.$store.dispatch(
          `${STORE_KEY}/addAttachments`,
          response.data.upload,
        )
        this.dropFile = response.data.upload
      })

    // @ts-ignore
    this.$v.form.product_image.$touch()
  }

  public submitObject() {
    this.$v.form.$touch()

    if (!this.$v.form.$error) {
      this.$store.dispatch(`${STORE_KEY}/saveObject`, this.$route.params.id)
        .then((result) => {
          this.$buefy.snackbar.open(`Данные успешно сохранены`)

          this.$router.push({ name: 'nomenclatures-list' })
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            type: 'is-warning',
            message: `При сохраненит возникли проблемы. Попробуйте позже.`,
          })
        })
    }
  }

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.$store.dispatch(`${STORE_KEY}/initState`, {
      id: this.$route.params.id,
      predefined: this.$route.query,
    }).then((object) => {
      this.dropFile = object.product_image
    })
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }
}
