var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('title-bar',{attrs:{"title-stack":_vm.titleStack}}),_c('section',{staticClass:"section is-main-section"},[(_vm.loaded && !_vm.fatalError)?[_c('section',{staticClass:"object-form"},[_c('div',{staticClass:"columns is-variable"},[_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"label":"Наименование","type":_vm.$v.form.name.$dirty ? (_vm.$v.form.name.$error ? 'is-danger' : 'is-success') : null,"message":_vm.getFieldError('name')}},[_c('b-input',{on:{"input":function($event){return _vm.changeValue('name')}},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('b-field',{staticStyle:{"margin":"10px"},attrs:{"label":"Фасовка","type":!_vm.form.packing
                      ? 'is-danger'
                      : 'is-success',"message":'Поле должно быть заполнено'}},[_c('b-input',{attrs:{"value":_vm.form.packing,"placeholder":"Укажите фасовку"},on:{"input":function($event){return _vm.changeValue('packing')}},model:{value:(_vm.form.packing),callback:function ($$v) {_vm.$set(_vm.form, "packing", $$v)},expression:"form.packing"}}),_c('p',{staticClass:"control"},[_c('b-select',{model:{value:(_vm.form.dim),callback:function ($$v) {_vm.$set(_vm.form, "dim", $$v)},expression:"form.dim"}},[_c('option',{attrs:{"value":"кг"}},[_vm._v("кг")]),_c('option',{attrs:{"value":"л"}},[_vm._v("л")]),_c('option',{attrs:{"value":"шт"}},[_vm._v("шт")])])],1)],1),_c('b-field',{staticStyle:{"margin":"10px"},attrs:{"label":"Категория:","type":!_vm.form.category
                      ? 'is-danger'
                      : 'is-success',"message":'Поле должно быть заполнено'}},[_c('v-select',{attrs:{"label":"name","options":_vm.categories,"placeholder":"Выбрать категории","required":""},on:{"input":function($event){return _vm.changeValue('category')}},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}})],1),_c('b-field',{staticStyle:{"margin":"10px"},attrs:{"label":"Прозводитель:","type":!_vm.form.category
                      ? 'is-danger'
                      : 'is-success',"message":'Поле должно быть заполнено'}},[_c('v-select',{attrs:{"label":"name","options":_vm.suppliers,"placeholder":"Выбрать Прозводителя","required":""},on:{"input":function($event){return _vm.changeValue('category')}},model:{value:(_vm.form.supplier),callback:function ($$v) {_vm.$set(_vm.form, "supplier", $$v)},expression:"form.supplier"}})],1),_c('b-field',{staticStyle:{"margin":"10px"},attrs:{"label":"Бренд:","type":!_vm.form.brand
                      ? 'is-danger'
                      : 'is-success',"message":'Поле должно быть заполнено'}},[_c('v-select',{attrs:{"label":"name","options":_vm.brands,"placeholder":"Выбрать бренд","required":""},on:{"input":function($event){return _vm.changeValue('brand')}},model:{value:(_vm.form.brand),callback:function ($$v) {_vm.$set(_vm.form, "brand", $$v)},expression:"form.brand"}})],1),_c('b-field',{attrs:{"label":"Описание продукта","type":_vm.$v.form.description.$dirty
                  ? _vm.$v.form.description.$error
                    ? 'is-danger'
                    : 'is-success'
                  : null,"message":_vm.getFieldError('conditions')}},[_c('wysiwyg',{attrs:{"value":_vm.form.description,"type":"textarea"},on:{"input":function($event){return _vm.changeValue('description', $event)}},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1),_c('div',{staticClass:"column is-6"},[_c('b-field',{staticStyle:{"margin":"10px"},attrs:{"label":"Фото","type":!_vm.dropFile
                  ? 'is-danger'
                  : 'is-success',"message":'Поле должно быть заполнено'}},[_c('b-upload',{attrs:{"value":_vm.dropFile,"drag-drop":"","expanded":""},on:{"input":_vm.uploadFile},model:{value:(_vm.dropFile),callback:function ($$v) {_vm.dropFile=$$v},expression:"dropFile"}},[_c('section',{},[_c('div',{staticClass:"content has-text-centered"},[(_vm.form.product_image)?_c('div',{staticStyle:{"text-align":"center","padding":"2rem 1rem","border-bottom":"2px solid #eee"}},[_c('img',{attrs:{"src":_vm.form.product_image.url}})]):_vm._e(),_c('p',[_c('b-icon',{attrs:{"icon":"upload","size":"is-large"}})],1),_c('p',[_vm._v("Бросьте файл сюда или нажмите для загрузки")])])])])],1),_c('div',{staticClass:"tags",staticStyle:{"margin":"10px"}},[(_vm.dropFile)?_c('span',{staticClass:"tag is-primary"},[_vm._v(" "+_vm._s(_vm.dropFile.name)+" ")]):_vm._e()]),_c('b-field',{staticStyle:{"margin":"10px"},attrs:{"label":"Шаблоны имен товаров"}},[_c('b-form-textarea',{attrs:{"type":!_vm.form.patterns
                    ? 'is-danger'
                    : 'is-success',"message":'Поле должно быть заполнено',"value":_vm.form.patterns,"rows":5,"placeholder":"Укажите шаблоны имен товаро в чеках. Можно использовать:\n        * для замены любого количества символов\n        ? для замены одного символа"},on:{"input":function($event){return _vm.changeValue('patterns')}},model:{value:(_vm.form.patterns),callback:function ($$v) {_vm.$set(_vm.form, "patterns", $$v)},expression:"form.patterns"}})],1)],1)]),_c('hr'),_c('div',{staticClass:"columns is-variable is-8"},[_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"actions"},[_c('b-button',{staticClass:"mr-2",attrs:{"disabled":(!_vm.$v.form.$anyDirty) || _vm.$v.form.$anyError,"type":"is-primary"},on:{"click":function($event){return _vm.submitObject()}}},[_vm._v("Сохранить ")]),_c('b-button',{staticClass:"mr-2",attrs:{"tag":"router-link","to":{ name: 'nomenclatures-list' },"exact":true,"type":"is-default"}},[_vm._v("Отмена ")]),(!_vm.$v.form.$anyDirty)?_c('b-tag',{attrs:{"type":"is-light"}},[_vm._v("Нет изменений для сохранения")]):_vm._e(),(_vm.$v.form.$error)?_c('b-tag',{attrs:{"type":"is-danger"}},[_vm._v("Присутствуют ошибки")]):_vm._e()],1)])])])]:(_vm.fatalError)?[_c('section',{staticStyle:{"padding":"2rem"}},[_c('b-message',{attrs:{"title":"Ошибка","type":"is-danger","closable":false}},[_vm._v(" Непредвиденная ошибка сервера ")])],1)]:(_vm.loading)?[_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.loading,"can-cancel":false},on:{"update:active":function($event){_vm.loading=$event}}})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }